import { BrokerServicePromiseClient } from "@lctech-tw/gin-proto/dist/js/star/broker/broker_grpc_web_pb";
import * as brokerPb from "@lctech-tw/gin-proto/dist/js/star/broker/broker_pb";

const service = () => {
  const client = new BrokerServicePromiseClient(
    process.env.VUE_APP_STAR_HOST || ""
  );
  const enableDevTools =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.__GRPCWEB_DEVTOOLS__ ||
    (() => {
      return;
    });
  enableDevTools([client]);
  return client;
};

const getStarList = async (payload: {
  uid?: number;
  phone?: string;
}): Promise<brokerPb.StarListRes> => {
  const req: brokerPb.StarListReq = new brokerPb.StarListReq();
  let res: brokerPb.StarListRes;
  payload.phone && req.setPhoneNumber(payload.phone);
  payload.uid && req.setUid(payload.uid);
  try {
    res = await service().starList(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

export const apiBroker = {
  getStarList,
};
