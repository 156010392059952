
import { apiOpen, humanCheckInStatus } from "@/lib/api_open";
import { apiBroker } from "@/lib/api_broker";
import { Toast } from "@/lib/toast";
import { MyCandidate, MyPostDetail } from "@/models/post";
import { userStore } from "@/store/user";
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  Ref,
  onMounted,
  nextTick,
} from "vue";
import { Reader } from "@/lib/html5-qrcode";
import { debounce, throttle } from "lodash";
import { parseJwt } from "@/lib/utils";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "ViewsPublicPostCheckIn",
  setup(props) {
    const route = useRoute();
    const reader: Ref<Reader | undefined> = ref();
    const state = reactive({
      isVisible: false,
      isChecking: false,
      optionLadyList: [] as MyCandidate[],
      optionIds: [] as string[],
      isFetching: false,
    });
    const postDetail: Ref<MyPostDetail> = ref(new MyPostDetail());
    const validateToken = async () => {
      try {
        const scanToken = route.query.token as string;
        const res = await apiOpen.validateScanToken(scanToken);
        postDetail.value = new MyPostDetail(res.getInfo());
      } catch (error) {
        Toast.bugOccur();
      }
    };
    const initReader = () => {
      reader.value = new Reader(
        "reader",
        { width: 250, height: 250 },
        (text: string) => {
          console.log("on reader: ", text);

          checkByReader(text);
        },
        (error) => {
          console.log("on reader error: ", error);

          return;
        }
      );
    };
    const checkByKeyboard = async () => {
      if (!state.isChecking) {
        state.isChecking = true;
        try {
          console.log("checkByKeyboard: ", state.optionIds);

          await check(state.optionIds);
        } catch (error) {
          undefined;
        } finally {
          state.isChecking = false;
        }
      }
    };
    const check = async (objectIds: string[]) => {
      try {
        console.log(route.query);

        const scanToken = route.query.token as string;
        const res = await apiOpen.createPostCheckInLog(
          objectIds.flat()[0],
          scanToken
        );
        Toast.info(humanCheckInStatus(res.getStatus()));
        state.optionIds = [];
      } catch (error) {
        Toast.bugOccur();
      }
    };
    const close = () => {
      reader.value?.stop();
    };
    const checkByReader = throttle(async (input: string) => {
      if (!state.isChecking) {
        state.isChecking = true;
        let uid: number = parseJwt(input)["uid"];
        const uuid: string = parseJwt(input)["uuid"];
        try {
          await window.dog.pan.api.ValidateQRcode(uid, uuid, input);
          await check([uuid]);
        } catch (error) {
          console.log(error);
          Toast.info("無效ID");
        } finally {
          state.isChecking = false;
        }
      }
    }, 3000);

    const fetchLadyList = debounce(async (search: string) => {
      state.optionLadyList = [];
      if (search) {
        state.isFetching = true;
        const uidRes = await apiBroker.getStarList({
          uid: Number(search),
        });
        state.optionLadyList = uidRes.getDataList().map(
          (e) =>
            new MyCandidate({
              uid: e.getUid(),
              objectId: e.getObjectId(),
              name: e.getDisplayName(),
              avatar: e.getAvatarUrl(),
            })
        );
        const phoneRes = await apiBroker.getStarList({
          phone: search,
        });
        state.optionLadyList.push(
          ...phoneRes.getDataList().map(
            (e) =>
              new MyCandidate({
                uid: e.getUid(),
                objectId: e.getObjectId(),
                name: e.getDisplayName(),
                avatar: e.getAvatarUrl(),
              })
          )
        );
        // remove duplications
        state.optionLadyList = [...new Set(state.optionLadyList)];
        state.isFetching = false;
      }
    }, 300);
    const onChange = () => {
      if (state.optionIds.length > 1) {
        state.optionIds.splice(0, 1);
      }
    };
    onMounted(() => {
      validateToken();
      initReader();
    });
    return {
      ...toRefs(state),
      checkByKeyboard,
      close,
      fetchLadyList,
      onChange,
      postDetail,
    };
  },
});
