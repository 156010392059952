import { OpenServicePromiseClient } from "@lctech-tw/post-proto/dist/js/post/open/open_grpc_web_pb";
import * as openPb from "@lctech-tw/post-proto/dist/js/post/open/open_pb";
import { CheckInStatus } from "@lctech-tw/post-proto/dist/js/external/enums/enums_pb";
const service = () => {
  const client = new OpenServicePromiseClient(
    process.env.VUE_APP_POST_HOST || ""
  );
  const enableDevTools =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.__GRPCWEB_DEVTOOLS__ ||
    (() => {
      return;
    });
  enableDevTools([client]);
  return client;
};

const validateScanToken = async (
  scanToken: string
): Promise<openPb.ValidScanTokenRes> => {
  const req: openPb.ValidScanTokenReq = new openPb.ValidScanTokenReq();
  let res: openPb.ValidScanTokenRes;
  req.setToken(scanToken);
  try {
    res = await service().validScanToken(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const createPostCheckInLog = async (
  starObjectId: string,
  scanToken: string
): Promise<openPb.CreatePostCheckInLogRes> => {
  const req: openPb.CreatePostCheckInLogReq =
    new openPb.CreatePostCheckInLogReq();
  let res: openPb.CreatePostCheckInLogRes;
  req.setToken(scanToken);
  req.setStarObjectId(starObjectId);
  try {
    res = await service().createPostCheckInLog(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

export const humanCheckInStatus = (status: CheckInStatus): string => {
  let result = "未定義狀態";
  switch (status) {
    case CheckInStatus.DEFAULT:
      result = "預設狀態";
      break;
    case CheckInStatus.DUPLICATE:
      result = "重複掃碼";
      break;
    case CheckInStatus.FAILED:
      result = "掃碼失敗";
      break;
    case CheckInStatus.NORMAL:
      result = "掃碼成功";
      break;
    default:
      break;
  }
  return result;
};

export const apiOpen = {
  validateScanToken,
  createPostCheckInLog,
};
